import { ComponentType, createElement } from 'react';

const isHMR = (() => {
  try {
    // @ts-ignore prevent type warning
    return process.env.NODE_ENV === 'development';
    // TODO currenly CRA default babel config does not support import.meta
    //return !!import.meta.hot || !!module.hot;
  } catch {
    return false;
  }
})();

export function fixHMR<Props extends {}>(
  Component: ComponentType<Props>,
): ComponentType<Props> {
  return isHMR ? (props: Props) => createElement(Component, props) : Component;
}
